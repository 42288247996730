/* Add this to your CSS file */

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: leftn;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff; /* Adjust if you have a specific background color */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* This adds a subtle shadow to the navbar */
  font-family: 'Arial', sans-serif; /* Replace with the font of your choice */
}

.navbar .nav-links a {
  text-decoration: none;
  color: #333; /* Adjust the color to match your design */
  margin-left: 20px;
  font-size: 18px;
}

.navbar .nav-links a:hover {
  color: #0591CE; /* Adjust hover color */
}
