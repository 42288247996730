/* Sidebar.css */

.sidebar {
  width: 300px;
  overflow-x: hidden;
  padding-top: 10px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: sticky;
  left: 0;
  top: 54px;
  height: calc(100vh - 64px);
}

.sidebar-view-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.sidebar-view {
  width: 720px;
}

.sidebar-container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.sidebar a {
  padding: 6px 8px 6px 20px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: block;
}

.sidebar a:hover {
  color: #0591CE; /* Adjust hover color */
}

.sublink {
  margin-left: 15px;
  font-size: 16px !important;
  font-weight: normal !important;
}
