.card-container {
  width: 95%;
  padding: 30px;  
  border: 1px solid #efefef;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: 20px;
  text-align: justify;
}

