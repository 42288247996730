.changelog {
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.update + .update {
  margin-top: 40px;
}

.date {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.fixes {
  /* list-style: none; */
  padding: 0;
}

.fix {
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 2px
}

/* Add additional styling as needed */
