/* ApiEndpoint.css */

/* .api-endpoint {
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.api-endpoint h2 {
  color: #333;
}

.api-endpoint p {
  color: #666;
}

.code-block {
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  overflow-x: auto;
}

.code-block pre {
  margin: 0;
  font-family: 'Courier New', monospace;
}

.tabs button {
  background-color: #e0e0e0;
  border: none;
  padding: 8px 16px;
  margin-right: 4px;
  cursor: pointer;
}

.tabs button:hover {
  background-color: #007bff;
  color: white;
}

.tabs .selected {
  background-color: #007bff;
  color: white; /* Optional: Change text color to white for better visibility */
}
