.home-container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.home-view {
  width: 730px;
}

.home-view p {
  text-align: justify;
}

.home-view h1, h2, h3 {
  color: #0591CE;
}

/* Define a style for links in their normal state */
a:link {
  color: #0077FF; /* Change to your desired link color */
  text-decoration: none; /* Remove underlines by default */
}

/* Define a style for visited links */
a:visited {
  color: #0077FF; /* Same color as :link */
}

/* Define a style for links on hover (when the mouse is over them) */
a:hover {
  color: #FF5500; /* Change to a different color for hover if desired */
}

/* Define a style for active links (when clicked) */
a:active {
  color: #FF5500; /* Same color as :hover */
}

.home-view img {
  width: 100%;
  margin-top: 20px;
}